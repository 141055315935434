.ProseMirror {
  &:focus-visible {
    outline: none;
  }

  > * + * {
    margin-top: 0.75em;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

   a {
    color: skyblue;
     cursor: pointer;
  }

  ul,
  ol {
    margin: 0.8em 0;
    padding: 0 0 0 2em;

    li {
      > ul,
      > ol {
        margin: 0;
      }

      p {
        display: inline;
        margin: 0;
      }
    }
  }

  ul[data-type='taskList'] {
    list-style: none;
    margin: 0.8em 0;
    padding: 0 0 0 0.9em;

    li {
      display: flex;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5em;
        user-select: none;
      }

      > div {
        flex: 1 1 auto;
      }
    }

    input[type='checkbox'] {
      cursor: pointer;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  p {
    margin: 0.8em 0;
  }

  code {
    background: rgba(#616161, 0.1);
    color: #616161;
    border: 1px solid rgba(#808080, 0.3);
    border-radius: 3px;
    padding: 1px;
  }

  pre {
    color: #24292e;
    background: #f5f7f9;
    font-family: "ui-monospace", "SFMono-Regular", "SF Mono", Menlo, Consolas,
    "Liberation Mono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
      border: none;
    }
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 1.5rem 0;
  }

  span[data-mention] {
    background-color: rgba(#005d71, 0.15);
    box-decoration-break: clone;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 4px;
    padding: 2px 4px;
    color: #005d71;

    > .char {
      display: inline-block;
      vertical-align: top;
      margin: 1px 2px 0 0;
      color: #0098b8;
    }
  }

  span[data-emoji-suggestion],
  span[data-emoji-replacer] {
    > img {
      width: 1.1em;
      height: 1.1em;
      margin: 0 0.05em;
      vertical-align: -0.25em;
    }
  }

  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: rgba(#111111, 0.3);
    pointer-events: none;
    height: 0;
    font-style: italic;
  }

  .color {
    white-space: nowrap;

    &::before {
      content: ' ';
      display: inline-block;
      width: 0.8em;
      height: 0.8em;
      border: 1px solid rgba(#808080, 0.3);
      vertical-align: middle;
      margin-right: 0.2em;
      margin-bottom: 0.15em;
      border-radius: 50%;
      background: var(--color);
    }
  }

  /*!
    Theme: GitHub
    Description: Light theme as seen on github.com
    Author: github.com
    Maintainer: @Hirse
    Updated: 2021-05-15
    Outdated base version: https://github.com/primer/github-syntax-light
    Current colors taken from GitHub's CSS
  */

  .hljs {
    color: #24292e;
    background: #ffffff;
  }

  .hljs-doctag,
  .hljs-keyword,
  .hljs-meta .hljs-keyword,
  .hljs-template-tag,
  .hljs-template-variable,
  .hljs-type,
  .hljs-variable.language_ {
    /* prettylights-syntax-keyword */
    color: #d73a49;
  }

  .hljs-title,
  .hljs-title.class_,
  .hljs-title.class_.inherited__,
  .hljs-title.function_ {
    /* prettylights-syntax-entity */
    color: #6f42c1;
  }

  .hljs-attr,
  .hljs-attribute,
  .hljs-literal,
  .hljs-meta,
  .hljs-number,
  .hljs-operator,
  .hljs-variable,
  .hljs-selector-attr,
  .hljs-selector-class,
  .hljs-selector-id {
    /* prettylights-syntax-constant */
    color: #005cc5;
  }

  .hljs-regexp,
  .hljs-string,
  .hljs-meta .hljs-string {
    /* prettylights-syntax-string */
    color: #032f62;
  }

  .hljs-built_in,
  .hljs-symbol {
    /* prettylights-syntax-variable */
    color: #e36209;
  }

  .hljs-comment,
  .hljs-code,
  .hljs-formula {
    /* prettylights-syntax-comment */
    color: #6a737d;
  }

  .hljs-name,
  .hljs-quote,
  .hljs-selector-tag,
  .hljs-selector-pseudo {
    /* prettylights-syntax-entity-tag */
    color: #22863a;
  }

  .hljs-subst {
    /* prettylights-syntax-storage-modifier-import */
    color: #24292e;
  }

  .hljs-section {
    /* prettylights-syntax-markup-heading */
    color: #005cc5;
    font-weight: bold;
  }

  .hljs-bullet {
    /* prettylights-syntax-markup-list */
    color: #735c0f;
  }

  .hljs-emphasis {
    /* prettylights-syntax-markup-italic */
    color: #24292e;
    font-style: italic;
  }

  .hljs-strong {
    /* prettylights-syntax-markup-bold */
    color: #24292e;
    font-weight: bold;
  }

  .hljs-addition {
    /* prettylights-syntax-markup-inserted */
    color: #22863a;
    background-color: #f0fff4;
  }

  .hljs-deletion {
    /* prettylights-syntax-markup-deleted */
    color: #b31d28;
    background-color: #ffeef0;
  }

  .hljs-char.escape_,
  .hljs-link,
  .hljs-params,
  .hljs-property,
  .hljs-punctuation,
  .hljs-tag {
    /* purposely ignored */
  }
}
@mixin white-card {
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 10px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
}

.WhiteCard {
  @include white-card;
}

.WhiteCard + h2 {
  margin: 20px 20px 5px;
}

.WhiteCard + .WhiteCard {
  margin-top: 20px;
}

.WhiteCard > pre {
  min-height: 15px;
  margin: 0;
  white-space: pre-wrap;
}

#Wrapper {
  //max-width: 1280px;
  margin: 0 auto;

  > nav {
    @include white-card;

    margin-bottom: 50px;
    padding: 18px 20px;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-block;
      line-height: 2.5;
      margin-right: 5px;
    }

    a {
      border: 2px solid transparent;
      border-radius: 5px;
      padding: 5px;
      color: #005D71;
      text-decoration: underline;
      font-weight: bold;

      &.selected {
        background: #007992;
        color: #ffffff;
        cursor: default;
      }
    }

    a:hover:not(.selected) {
      border: 2px solid #007992;
    }
  }
}
